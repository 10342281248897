import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://38b11aeb7ca5600adbd0881705180fe5@o4506247408713728.ingest.sentry.io/4506247557152768",
  debug: false,
  environment: "production",
  release: "v1.3.0p (ac98908)",
  tracesSampleRate: 0.01,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});